import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import code from '@code-wallet/elements';

const icons: string[] = [
  'fa-heart', 'fa-star', 'fa-car', 'fa-bell',
  'fa-cloud', 'fa-tree', 'fa-gift', 'fa-music'
];

interface Card {
  id: number;
  icon: string;
}

function App() {
  const [cards, setCards] = useState<Card[]>([]);
  const [flipped, setFlipped] = useState<number[]>([]);
  const [solved, setSolved] = useState<number[]>([]);
  const [moves, setMoves] = useState<number>(0);
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [endTime, setEndTime] = useState<number | null>(null);
  const [gameStarted, setGameStarted] = useState<boolean>(false);

  const paymentButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!gameStarted) {
      fetchPaymentIntent();
    }
  }, [gameStarted]);

  const fetchPaymentIntent = async () => {
    try {
      const response = await fetch('/.netlify/functions/create-payment');
      const data = await response.json();

      if (data.clientSecret) {
        const { button } = code.elements.create('button', {
          clientSecret: data.clientSecret,
        });

        if (paymentButtonRef.current && button) {
          button.mount(paymentButtonRef.current);

          button.on('success', async () => {
            return new Promise<void>((resolve) => {
              setGameStarted(true);
              initializeGame();
              resolve();
            });
          });
        }
      }
    } catch (error) {
      console.error('Failed to fetch payment intent', error);
    }
  };

  const initializeGame = () => {
    const shuffledCards: Card[] = [...icons, ...icons]
      .sort(() => Math.random() - 0.5)
      .map((icon, index) => ({ id: index, icon }));
    setCards(shuffledCards);
    setFlipped([]);
    setSolved([]);
    setMoves(0);
    setGameOver(false);
    setStartTime(Date.now());
    setEndTime(null);
  };

  const handleCardClick = (id: number) => {
    if (flipped.length === 2 || solved.includes(id) || flipped.includes(id)) return;
    
    setFlipped((prev) => [...prev, id]);
    setMoves((prev) => prev + 1);

    if (flipped.length === 1) {
      const firstCard = cards[flipped[0]];
      const secondCard = cards[id];
      if (firstCard.icon === secondCard.icon) {
        setSolved((prev) => [...prev, flipped[0], id]);
      }
      setTimeout(() => setFlipped([]), 1000);
    }
  };

  useEffect(() => {
    if (solved.length === cards.length && cards.length > 0) {
      setGameOver(true);
      setEndTime(Date.now());
    }
  }, [solved, cards]);

  const formatTime = (milliseconds: number): string => {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    return `${minutes}:${(seconds % 60).toString().padStart(2, '0')}`;
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Memory Game</h1>
        {!gameStarted ? (
          <div>
            <p>Pay 10 cents to start the game</p>
            <div ref={paymentButtonRef} />
          </div>
        ) : (
          <>
            <div className="game-stats">
              <p>Moves: {moves}</p>
              <p>Time: {startTime && (endTime || Date.now()) - startTime > 0 ? formatTime((endTime || Date.now()) - startTime) : '0:00'}</p>
            </div>
            <div className="game-board">
              {cards.map((card) => (
                <div
                  key={card.id}
                  className={`card ${flipped.includes(card.id) || solved.includes(card.id) ? 'flipped' : ''} ${solved.includes(card.id) ? 'solved' : ''}`}
                  onClick={() => handleCardClick(card.id)}
                >
                  <div className="card-inner">
                    <div className="card-front"></div>
                    <div className="card-back">
                      <i className={`fas ${card.icon}`}></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {gameOver && (
              <div className="celebration">
                <h2>Congratulations!</h2>
                <p>You completed the game in {moves} moves and {formatTime(endTime! - startTime!)}!</p>
                <button onClick={() => setGameStarted(false)}>Play Again</button>
              </div>
            )}
          </>
        )}
      </header>
    </div>
  );
}

export default App;